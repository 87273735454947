"use client";

import React, { useEffect } from "react";

import { ServiceErrorPage } from "~/components/service-error-page/component";
import { EventNames as NREventNames } from "~/constants/new-relic";
import { getMessageFromGraphQLError } from "~/helpers/get-message-from-graphql-error";

import { Logger } from "../../utils/logger";

export default function Error({ error }: { error: Error }) {
  useEffect(() => {
    Logger.getLogger("Global error handler").error(error);

    if (!IS_SERVER && !IS_TEST) {
      // eslint-disable-next-line no-restricted-globals
      window?.newrelic?.addPageAction(NREventNames.renderOopsPage, {
        error:
          getMessageFromGraphQLError(error) ||
          `Unhandled error ${JSON.stringify(error)}`,
        stack: error?.stack,
      });
    }
  }, [error]);

  return <ServiceErrorPage />;
}
